import * as actionTypes from '../../actionTypes';

const initialState = {
  employees: {
    result: [],
    __metadata: {},
  },
};

export default function employeeReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.INIT_EMPLOYEES:
      return {
        ...state,
        employees: action.payload,
      };
    case actionTypes.ADD_NEW_EMPLOYEE:
      const addEmployee = {...state.employees};
      addEmployee.result.push(action.payload);
      return {
        ...state,
        employees: addEmployee,
      };
    case actionTypes.UPDATE_EMPLOYEE:
      const updateEmployee = {...state.employees};
      const updateIndex = updateEmployee.result.findIndex(
        (a) => a._id === action.payload._id
      );
      if (updateIndex > -1) {
        updateEmployee.result[updateIndex] = action.payload;
      }
      return {
        ...state,
        employees: updateEmployee,
      };
    case actionTypes.DELETE_EMPLOYEE:
      const deleteEmployee = {...state.employees};
      const deleteIndex = deleteEmployee.result.findIndex(
        (a) => a._id === action.payload
      );
      if (deleteIndex > -1) {
        deleteEmployee.result.splice(deleteIndex, 1);
      }
      return {
        ...state,
        employees: deleteEmployee,
      };
    case 'LOGOUT':
      return {
        employees: {
          result: [],
          __metadata: {},
        },
      };
    default:
      return state;
  }
}
