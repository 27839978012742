export const fetchData = (type) => ({
  type: 'DATA_FETCHING',
  payload: type,
});

export const setLoading = (value) => ({
  type: 'LOADING',
  payload: value,
});

export const setRealtimeConnected = (value) => ({
  type: 'SET_REALTIME_CONNECTED',
  payload: value,
});

export const setRealtimeFailed = (value) => ({
  type: 'SET_REALTIME_FAILED',
  payload: value,
});

export const setRealtimeDisconnected = (value) => ({
  type: 'SET_REALTIME_DISCONNECTED',
  payload: value,
});

export const setRealtimeClosed = (value) => ({
  type: 'SET_REALTIME_CLOSED',
  payload: value,
});

export const setLanguage = (language) => ({
  type: 'SET_LANGUAGE',
  payload: language,
});

export const addServices = (tableNo, services) => ({
  type: 'SET_ADD_NEW_SERVICES',
  payload: {table_no: tableNo, services: services},
});

export const removeServices = (tableNo) => ({
  type: 'SET_REMOVE_SERVICES',
  payload: tableNo,
});
