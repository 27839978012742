import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import PropTypes from 'prop-types';
import {useStyles} from '../styles';
import {useRouteMatch} from 'react-router';
import MenuSelection from '../../MenuSelection';
import DashboardIcon from '../svgs/DashboardIcon';
import ManageItemsIcon from '../svgs/ManageItemsIcon';
import LogoutIcon from '../svgs/LogoutIcon';
import {useDispatch} from 'react-redux';
import {push} from 'connected-react-router';
import {logout} from '../../../services/actions/authActions';
import RestInfo from './RestInfo';
import PreferencesIcon from '../svgs/PreferencesIcon';
import SalesReportIcon from '../svgs/SalesReportIcon';
// import PayoutIcon from '../svgs/PayoutIcon';
import {useTranslation} from 'react-i18next';
import LangSelector from './LangSelector';
import ProfileIcon from '../svgs/ProfileIcon';
import EmployeeIcon from '../svgs/EmployeeIcon';
import CrmIcon from '../svgs/CrmIcon';

function MenuDrawer({open, handleClose}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const menus = [
    {
      label: t('Dashboard'),
      active: useRouteMatch('/dashboard'),
      handleMenu: () => {
        handleClose();
        dispatch(push('/dashboard'));
      },
      icon: <DashboardIcon />,
    },
    {
      label: t('Manage Items'),
      active: useRouteMatch('/manage-items'),
      handleMenu: () => {
        handleClose();
        dispatch(push('/manage-items'));
      },
      icon: <ManageItemsIcon />,
    },
    {
      label: t('Preferences'),
      active: useRouteMatch('/preferences'),
      handleMenu: () => {
        handleClose();
        dispatch(push('/preferences'));
      },
      icon: <PreferencesIcon />,
    },
    {
      label: t('Reports'),
      active: useRouteMatch('/reports'),
      handleMenu: () => {
        handleClose();
        dispatch(push('/reports'));
      },
      icon: <SalesReportIcon />,
    },
    {
      label: t('Employees'),
      active: useRouteMatch('/employees'),
      handleMenu: () => {
        handleClose();
        dispatch(push('/employees'));
      },
      icon: <EmployeeIcon />,
    },
    {
      label: t('CRM'),
      active: useRouteMatch('/crm'),
      handleMenu: () => {
        handleClose();
        dispatch(push('/crm'));
      },
      icon: <CrmIcon />,
    },
    {
      label: t('Profile'),
      active: useRouteMatch('/profile'),
      handleMenu: () => {
        handleClose();
        dispatch(push('/profile'));
      },
      icon: <ProfileIcon />,
    },
    {
      label: t('Logout'),
      active: false,
      handleMenu: () => {
        handleClose();
        dispatch(logout());
      },
      icon: <LogoutIcon />,
    },
  ];

  return (
    <Drawer open={open} onClose={handleClose}>
      <div className={classes.drawerRoot}>
        <RestInfo />
        <div className={classes.menuList}>
          {menus.map((menu, index) => (
            <MenuSelection
              key={index}
              active={menu.active}
              label={menu.label}
              handleClick={menu.handleMenu}
            >
              {menu.icon}
            </MenuSelection>
          ))}
        </div>
        <div className={classes.bottomArea}>
          <LangSelector />
          <div className={classes.version}>
            V-{window.localStorage.getItem('appVersion')}
          </div>
        </div>
      </div>
    </Drawer>
  );
}

MenuDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default MenuDrawer;
