import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function CrmIcon() {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m32 45c-4.963 0-9 4.038-9 9s4.037 9 9 9 9-4.038 9-9-4.037-9-9-9zm-3.823 14.857c.499-1.661 2.045-2.857 3.823-2.857s3.324 1.196 3.823 2.857c-1.101.72-2.413 1.143-3.823 1.143s-2.722-.423-3.823-1.143zm1.823-6.857c0-1.103.897-2 2-2s2 .897 2 2-.897 2-2 2-2-.897-2-2zm7.403 5.445c-.544-1.141-1.427-2.078-2.522-2.684.69-.718 1.119-1.689 1.119-2.761 0-2.206-1.794-4-4-4s-4 1.794-4 4c0 1.072.429 2.043 1.119 2.762-1.095.606-1.977 1.542-2.522 2.684-.997-1.211-1.597-2.76-1.597-4.446 0-3.86 3.141-7 7-7s7 3.14 7 7c0 1.686-.6 3.235-1.597 4.445z" />
      <path d="m32 1c-4.963 0-9 4.038-9 9 0 1.165.23 2.275.634 3.298-.515-.18-1.059-.298-1.634-.298-1.654 0-3 1.347-3 3v2.472c0 1.826-.973 3.544-2.539 4.484-1.479.888-2.594 2.278-3.138 3.913l-.023.07-12.3-1.025v17.086h31c1.654 0 3-1.346 3-3 0-.395-.081-.77-.22-1.115 1.275-.347 2.22-1.502 2.22-2.885 0-.771-.301-1.468-.78-2 .48-.532.78-1.229.78-2s-.301-1.468-.78-2c.48-.532.78-1.229.78-2 0-1.653-1.345-3-2.999-3h-5.001c-1.103 0-2-.897-2-2v-5c0-.192-.036-.374-.057-.56 1.443.983 3.183 1.56 5.057 1.56 4.963 0 9-4.038 9-9s-4.037-9-9-9zm-19 40h-10v-12.913l10 .833zm16-14h5.001c.551 0 .999.449.999 1s-.448 1-1 1h-7v2h7c.552 0 1 .449 1 1s-.448 1-1 1h-7v2h7c.552 0 1 .449 1 1s-.448 1-1 1h-7v2h5c.552 0 1 .448 1 1s-.448 1-1 1h-17v-12.838l.221-.66c.394-1.183 1.199-2.188 2.27-2.83 2.165-1.299 3.51-3.675 3.51-6.199v-2.472c0-.551.449-1 1-1 1.654 0 3 1.346 3 3v5c-.001 2.205 1.793 3.999 3.999 3.999zm3-10c-3.859 0-7-3.14-7-7s3.141-7 7-7 7 3.14 7 7-3.141 7-7 7z" />
      <path d="m54 23c-4.963 0-9 4.038-9 9s4.037 9 9 9 9-4.038 9-9-4.037-9-9-9zm0 16c-3.859 0-7-3.14-7-7s3.141-7 7-7 7 3.14 7 7-3.141 7-7 7z" />
      <path d="m57 29v4h-2v-6h-2v6h-2v-2h-2v4h10v-6z" />
      <path d="m31 9h2v2h-2z" />
      <path d="m35 9h2v2h-2z" />
      <path d="m27 9h2v2h-2z" />
      <path d="m53.152 18.531 1.336 2.129 1.492-7.464-1.961-.393-.536 2.683c-2.584-3.42-5.83-5.979-10.06-7.958l-.847 1.812c4.646 2.173 8.007 5.094 10.576 9.191z" />
      <path d="m7.528 20.577 1.813.847c2.171-4.644 5.092-8.005 9.19-10.576l2.128-1.335-7.463-1.493-.392 1.96 2.683.537c-3.422 2.585-5.981 5.831-7.959 10.06z" />
      <path d="m10.848 45.469-1.336-2.129-1.492 7.464 1.961.393.536-2.683c2.584 3.42 5.83 5.979 10.06 7.958l.848-1.812c-4.647-2.173-8.008-5.094-10.577-9.191z" />
      <path d="m56.472 43.423-1.813-.847c-2.171 4.644-5.092 8.005-9.19 10.576l-2.128 1.335 7.463 1.493.393-1.961-2.683-.537c3.421-2.584 5.98-5.83 7.958-10.059z" />
    </SvgIcon>
  );
}

export default CrmIcon;
