import * as actionTypes from '../../actionTypes';

const initialState = {
  customerDetails: {},
  templates: [],
  customerQRDetails: [],
  loyalty: [],
};

export default function crmReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.INIT_CUSTOMER_DETAILS:
      return {
        ...state,
        customerDetails: action.payload,
      };
    case actionTypes.INIT_TEMPLATE_DETAILS:
      return {
        ...state,
        templates: action.payload,
      };
    case actionTypes.ADD_NEW_TEMPLATE:
      return {
        ...state,
        templates: [...state.templates, action.payload],
      };
    case actionTypes.UPDATE_TEMPLATE:
      const tempUpdate = [...state.templates];
      const updateIndex = tempUpdate.findIndex(
        (a) => a._id === action.payload._id
      );
      if (updateIndex > -1) {
        tempUpdate[updateIndex] = action.payload;
      }
      return {
        ...state,
        templates: tempUpdate,
      };
    case actionTypes.DELETE_TEMPLATE:
      const tempDelete = [...state.templates];
      const deleteIndex = tempDelete.findIndex((a) => a._id === action.payload);
      if (deleteIndex > -1) {
        tempDelete.splice(deleteIndex, 1);
      }
      return {
        ...state,
        templates: tempDelete,
      };
    case actionTypes.INIT_CUSTOMER_QR_VOUCHER:
      return {
        ...state,
        customerQRDetails: action.payload,
      };
    case actionTypes.INIT_LOYALTY:
      return {
        ...state,
        loyalty: action.payload,
      };
    case actionTypes.ADD_LOYALTY:
      return {
        ...state,
        loyalty: [...state.loyalty, action.payload],
      };
    case actionTypes.DELETE_LOYALTY:
      const tempDeleteLoyalty = [...state.loyalty];
      const tempDeleteLoyaltyIndex = tempDeleteLoyalty.findIndex(
        (a) => a._id === action.payload
      );
      if (tempDeleteLoyaltyIndex > -1) {
        tempDeleteLoyalty.splice(tempDeleteLoyaltyIndex, 1);
      }
      return {
        ...state,
        loyalty: tempDeleteLoyalty,
      };
    case 'LOGOUT':
      return {
        customerDetails: {},
        templates: [],
        customerQRDetails: [],
        loyalty: [],
      };
    default:
      return state;
  }
}
