import {http} from '../../http';
import {API_URL} from '../../../utils/constants';
import {setLoading} from '../miscActions';
import {toast} from 'react-toastify';
import moment from 'moment';

export const initSales = (list) => {
  return {
    type: 'INIT_SALES',
    payload: list,
  };
};

export const updateSalesCount = (data) => {
  return {
    type: 'UPDATE_SALES_COUNT',
    payload: data,
  };
};

export const downloadCSV = (fromDate, toDate, deleted) => {
  return (dispatch, getState) => {
    const authState = getState();
    const restaurantID = authState.auth.restData._id;
    dispatch(setLoading(true));
    let url = `${API_URL}/stores/${restaurantID}/sales?from=${fromDate}&to=${toDate}`;
    if (deleted) {
      url += '&deleted=true';
    }
    http
      .get(url, {
        responseType: 'blob',
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `sales report (${new Date().toDateString()}).csv`
        );
        document.body.appendChild(link);
        link.click();
        dispatch(setLoading(false));
      })
      .catch(() => {
        toast.error('unable to retrive data for given sales date');
        dispatch(setLoading(false));
      });
  };
};

export const downloadEAI = (fromDate, toDate) => {
  return (dispatch, getState) => {
    const authState = getState();
    const restaurantID = authState.auth.restData._id;
    dispatch(setLoading(true));
    const url = `${API_URL}/stores/${restaurantID}/sales/text?from=${fromDate}&to=${toDate}`;
    http
      .get(url, {
        responseType: 'blob',
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `FC00133_${moment(toDate)
            .utc()
            .subtract(4, 'hours')
            .format('DDMMYYYY')}.EAI`
        );
        document.body.appendChild(link);
        link.click();
        dispatch(setLoading(false));
      })
      .catch(() => {
        toast.error('unable to retrive data for given sales date');
        dispatch(setLoading(false));
      });
  };
};

export const getSalesInfo = (
  fromDate,
  toDate,
  paymentMode,
  orderType,
  deleted
) => {
  return (dispatch, getState) => {
    const restaurantID = getState().auth.restData._id;
    dispatch(setLoading(true));
    let url = `${API_URL}/stores/${restaurantID}/orders?from=${fromDate}&to=${toDate}&limit=1000&page=1`;
    if (paymentMode && paymentMode.trim() !== '') {
      url += `&payment_modes=${paymentMode}`;
    }
    if (orderType && orderType.trim() !== '') {
      url += `&order_types=${orderType}`;
    }
    if (deleted) {
      url += '&deleted=true';
    }
    http
      .get(url)
      .then((res) => {
        dispatch(initSales(res.data));
        dispatch(setLoading(false));
      })
      .catch(() => {
        toast.error('Unable to Retrieve Data for given Sales Date');
        dispatch(setLoading(false));
      });
  };
};

export const getOrderSummary = (fromDate, toDate) => {
  return (dispatch, getState) => {
    const authState = getState();
    const restaurantID = authState.auth.authData._id;
    dispatch(setLoading(true));
    http
      .get(
        `${API_URL}/stores/${restaurantID}/orderSummary?from=${fromDate}&to=${toDate}`
      )
      .then((res) => {
        dispatch({type: 'SAVE_ORDER_SUMMARY', payload: res.data});
        dispatch(setLoading(false));
      })
      .catch(() => {
        toast.error('unable to retrive data for given sales date');
        dispatch(setLoading(false));
      });
  };
};

export const getSalesCountInfo = () => {
  return (dispatch, getState) => {
    const restaurantID = getState().auth.restData._id;
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/stores/${restaurantID}/orders/details`)
      .then((res) => {
        dispatch(updateSalesCount(res.data));
        dispatch(setLoading(false));
      })
      .catch(() => {
        toast.error('Unable to Retrive Data');
        dispatch(setLoading(false));
      });
  };
};

export const deleteSalesReport = (id) => {
  return (dispatch) => {
    return new Promise((resolve, rej) => {
      dispatch(setLoading(true));
      http
        .delete(`${API_URL}/orders/${id}`, {
          data: {
            visible: false,
            reason: 'Delete sales Data',
          },
        })
        .then((res) => {
          dispatch({type: 'SALES_DELETE', payload: res.data});
          dispatch(setLoading(false));
          dispatch(getSalesCountInfo());
          resolve();
        })
        .catch((err) => {
          toast.error('Unable to Delete Data');
          dispatch(setLoading(false));
          rej(err);
        });
    });
  };
};

export const changeOrderPaymentMode = (id, data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/stores/order/${id}/payment`, data)
      .then((res) => {
        dispatch({type: 'UPDATE_SALES_PAYMENT', payload: res.data});
        dispatch(setLoading(false));
      })
      .catch((err) => {
        toast.error('Unable to Delete Data');
        dispatch(setLoading(false));
      });
  };
};

export const clearSalesInfo = () => {
  return (dispatch) => {
    dispatch(initSales([]));
  };
};

export const getShortSummary = (from, to) => {
  return (dispatch, getState) => {
    dispatch(setLoading(true));
    const storeID = getState().auth.restData._id;
    http
      .get(`${API_URL}/stores/${storeID}/short_summary?from=${from}&to=${to}`)
      .then((res) => {
        dispatch({type: 'INIT_SHORT_SUMMARY', payload: res.data});
        dispatch(setLoading(false));
      })
      .catch((err) => {
        toast.error('Unable to get summary');
        dispatch(setLoading(false));
      });
  };
};

export const getAuditBills = () => {
  return (dispatch, getState) => {
    dispatch(setLoading(true));
    const storeID = getState().auth.restData._id;
    http
      .get(`${API_URL}/stores/${storeID}/audit_bills`)
      .then((res) => {
        dispatch({type: 'INIT_AUDIT_BILLS', payload: res.data});
        dispatch(setLoading(false));
      })
      .catch((err) => {
        toast.error('Unable to get bills/kots');
        dispatch(setLoading(false));
      });
  };
};

export const getCategoryWiseSales = (from, to) => {
  return (dispatch, getState) => {
    dispatch(setLoading(true));
    const storeID = getState().auth.restData._id;
    http
      .get(`${API_URL}/stores/${storeID}/category/sales?from=${from}&to=${to}`)
      .then((res) => {
        dispatch({type: 'INIT_CATEGORY_WISE_SALES', payload: res.data});
        dispatch(setLoading(false));
      })
      .catch((err) => {
        toast.error('Unable to get sales');
        dispatch(setLoading(false));
      });
  };
};
