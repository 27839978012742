import React from 'react';
import {useStyles} from '../styles';
import RefreshIcon from '../svgs/RefreshIcon';
import IconButton from '@material-ui/core/IconButton';
import {useDispatch, useSelector} from 'react-redux';
import {getTaxes} from '../../../services/actions/preferencesActions';
import {
  getCategoryInfo,
  getGlobalAddonInfo,
  getIcons,
  getItemInfo,
  getPreferences,
  getRestVouchers,
} from '../../../services/thunks';
import {
  getCategoryAnalytics,
  getmostLikedItems,
  getSalesAnalytics,
} from '../../../services/actions/analyticsActions';
import {
  getAllEmployees,
  getCustomerDetails,
  getRestData,
} from '../../../services/actions';

function HotMenu() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const route = useSelector((state) => state.router.location.pathname);

  const storeID = useSelector(
    (state) => state.auth.restaurant && state.auth.restaurant._id
  );

  const handleRefreshClicked = async (path) => {
    switch (path) {
      case '/dashboard':
        const startDate = new Date().setDate(new Date().getDate() - 7);
        const endDate = new Date().getTime() / 1000;
        await dispatch(getCategoryAnalytics());
        await dispatch(getmostLikedItems());
        await dispatch(getSalesAnalytics(startDate, endDate));
        break;
      case '/employees':
        await dispatch(getAllEmployees());
        break;
      case '/manage-items':
        await dispatch(getItemInfo());
        await dispatch(getGlobalAddonInfo());
        await dispatch(getCategoryInfo());
        await dispatch(getIcons());
        break;
      case '/preferences':
        await dispatch(getRestData(storeID));
        await dispatch(getRestVouchers());
        await dispatch(getPreferences());
        await dispatch(getTaxes());
        break;
      case '/crm':
        dispatch(getCustomerDetails());
        break;
      default:
        break;
    }
  };
  return (
    <div className={classes.iconFlex}>
      <IconButton onClick={() => handleRefreshClicked(route)}>
        <RefreshIcon />
      </IconButton>
    </div>
  );
}

export default HotMenu;
