// eslint-disable-next-line no-unused-vars
import * as actionTypes from '../../actionTypes';

const initState = {
  isDataFetching: false,
  isLoading: false,
  language: localStorage.getItem('language')
    ? localStorage.getItem('language')
    : 'english',
  typeIndex: 0,
  services: [],
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case 'DATA_FETCHING':
      return Object.assign({}, state, {isDataFetching: action.payload});
    case 'LOADING':
      return Object.assign({}, state, {isLoading: action.payload});
    case 'SET_LANGUAGE':
      localStorage.setItem('language', action.payload);
      return Object.assign({}, state, {
        language: action.payload,
      });
    case 'SET_NOTIFICATION_SOUND':
      return {
        ...state,
        notify_sound: action.payload,
      };
    case 'SELECTED_TYPE':
      return {
        ...state,
        type: action.payload,
      };
    case 'TYPE_INDEX':
      return {
        ...state,
        typeIndex: action.payload,
      };
    case 'PRINT_FIRST_KOT':
      return {
        ...state,
        printFirstKOT: action.payload,
      };
    case 'SET_ADD_NEW_SERVICES':
      const add_services = [...state.services];
      add_services.push(action.payload);
      return {
        ...state,
        services: add_services,
      };
    case 'SET_REMOVE_SERVICES':
      const remove_services = [...state.services];
      const remove_services_index = remove_services.findIndex(
        (a) => a.table_no === action.payload
      );
      if (remove_services_index > -1) {
        remove_services.splice(remove_services_index, 1);
      }
      return {
        ...state,
        services: remove_services,
      };
    case 'LOGOUT':
      return {
        ...state,
        isDataFetching: false,
        isLoading: false,
        typeIndex: 0,
        services: [],
      };
    default:
      return state;
  }
};

export default reducer;
