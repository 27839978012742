import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function EmployeeIcon() {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="34.75" y="317" width="37.5" height="120" />
      <path d="M207.25,182.5c0-19.927,7.103-38.226,18.908-52.5H169.75v105h-30V130h-52.5c-28.949,0-52.5,23.551-52.5,52.5V287h37.5   V182.5h30V512h37.5V316.5h30V512h37.5V182.5z" />
      <path d="M204.75,50c0-27.57-22.43-50-50-50s-50,22.43-50,50s22.43,50,50,50S204.75,77.57,204.75,50z" />
      <rect x="237.25" y="317" width="37.5" height="120" />
      <path d="M409.75,182.5h30V317c0,10.339,8.411,18.75,18.75,18.75s18.75-8.411,18.75-18.75l0-134.5c0-28.949-23.551-52.5-52.5-52.5   h-52.5v105h-30V130h-52.5c-28.949,0-52.5,23.551-52.5,52.5V287h37.5V182.5h30V512h37.5V316.5h30V512h37.5V182.5z" />
      <path d="M357.25,100c27.57,0,50-22.43,50-50s-22.43-50-50-50s-50,22.43-50,50S329.679,100,357.25,100z" />
    </SvgIcon>
  );
}

export default EmployeeIcon;
