import {toast} from 'react-toastify';
import {API_URL} from '../../../utils/constants';
import {http} from '../../http';
import {setLoading} from '../miscActions';
import * as actionTypes from '../../actionTypes';

export const getAllEmployees = () => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/store_emp`)
      .then((res) => {
        dispatch(setLoading(false));
        dispatch({type: actionTypes.INIT_EMPLOYEES, payload: res.data});
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        dispatch(setLoading(false));
      });
  };
};

export const addNewStaff = (data, cb) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/store_emp`, data)
      .then(async (res) => {
        await dispatch(setLoading(false));
        await dispatch({type: actionTypes.ADD_NEW_EMPLOYEE, payload: res.data});
        if (data) {
          cb();
        }
        await dispatch(getAllEmployees());
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        dispatch(setLoading(false));
      });
  };
};

export const updateStaff = (id, data, cb) => {
  return (dispatch) => {
    dispatch(setLoading(true));

    http
      .put(`${API_URL}/store_emp/${id}`, data)
      .then((res) => {
        dispatch({type: actionTypes.UPDATE_EMPLOYEE, payload: res.data});
        cb(res.data);
        dispatch(setLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        dispatch(setLoading(false));
      });
  };
};

export const deleteStaff = (id, cb) => {
  return (dispatch) => {
    dispatch(setLoading(true));

    http
      .delete(`${API_URL}/store_emp/${id}`)
      .then(() => {
        dispatch({type: actionTypes.DELETE_EMPLOYEE, payload: id});
        cb();
        dispatch(setLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        dispatch(setLoading(false));
      });
  };
};
