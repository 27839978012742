import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function PreferencesIcon() {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19.7937 4.32335C19.6918 3.91608 19.1842 3.77433 18.8875 4.07121L16.478 6.48071C15.943 7.01554 15.0756 7.01554 14.5406 6.48071L13.4351 5.37518C12.9001 4.84019 12.9001 3.97281 13.4351 3.43773L15.8152 1.05755C16.1141 0.758707 15.9686 0.246207 15.5573 0.149891C14.9941 0.0182788 14.3988 -0.0303087 13.7848 0.0186878C11.146 0.229234 8.71719 2.70364 8.55294 5.3459C8.49073 6.34804 8.69322 7.29541 9.08777 8.13461L0.664699 15.3729C0.285855 15.6984 0.0589904 16.1664 0.0380094 16.6654C0.016783 17.1644 0.203322 17.6497 0.553291 18.006L1.98073 19.4596C2.3341 19.8196 2.82202 20.0153 3.32626 19.9991C3.83054 19.9827 4.30488 19.7563 4.63444 19.3745L11.9303 10.9214C12.7448 11.285 13.6566 11.4704 14.6192 11.4104C17.2793 11.2444 19.7542 8.79615 19.9479 6.13814C19.9938 5.50847 19.9373 4.89871 19.7937 4.32335Z" />
    </SvgIcon>
  );
}

export default PreferencesIcon;
