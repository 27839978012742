import {API_URL} from '../../../utils/constants';
import * as actionTypes from '../../actionTypes';
import {setLoading} from '../miscActions';
import {http} from '../../http';
import {toast} from 'react-toastify';

export const getCustomerDetails = () => {
  return (dispatch, getState) => {
    const storeID = getState().auth.restaurant._id;
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/crm/cohorts/${storeID}`)
      .then(async (res) => {
        await dispatch(setLoading(false));
        await dispatch({
          type: actionTypes.INIT_CUSTOMER_DETAILS,
          payload: res.data,
        });
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to get customer details');
      });
  };
};
