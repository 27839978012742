const initialState = {
  list: [],
  total_Count: 0,
  total_Amount: 0,
  salesCount: {},
  orderSummary: [],
  snackableSummary: [],
  auditBills: [],
  catgeoryWiseSales: [],
};

export default function salesReportReducer(state = initialState, action) {
  switch (action.type) {
    case 'INIT_SALES':
      const data =
        action.payload && action.payload.orders ? action.payload.orders : [];
      const orderCount =
        action.payload && action.payload.orderCount
          ? action.payload.orderCount
          : 0;
      const orderTotal =
        action.payload &&
        action.payload.orderTotal &&
        action.payload.orderTotal[0]
          ? action.payload.orderTotal[0].total
          : 0;
      return {
        ...state,
        list: data,
        total_Count: orderCount,
        total_Amount: orderTotal,
      };
    case 'SAVE_ORDER_SUMMARY':
      const value = action.payload;
      const newOrderSummary = [
        {
          _id: 'cash',
          total: 0,
        },
        {
          _id: 'card',
          total: 0,
        },
        {
          _id: 'online',
          total: 0,
        },
        {
          _id: 'wallet',
          total: 0,
        },
      ];

      value.length &&
        value.forEach((obj) => {
          const index = newOrderSummary.findIndex((a) => a._id === obj._id);
          if (index > -1) {
            newOrderSummary[index].total = obj.total;
          }
        });
      return {
        ...state,
        orderSummary: newOrderSummary,
      };
    case 'UPDATE_SALES_PAYMENT':
      const update_list = [...state.list];
      const update_index = update_list.findIndex(
        (a) => a._id === action.payload._id
      );
      if (update_index > -1) {
        update_list[update_index] = action.payload;
      }
      return {
        ...state,
        list: update_list,
      };
    case 'SALES_DELETE':
      const temp_list = [...state.list];
      const temp_index = temp_list.findIndex(
        (a) => a._id === action.payload._id
      );
      if (temp_index > -1) {
        temp_list.splice(temp_index, 1);
      }
      return {
        ...state,
        list: temp_list,
      };
    case 'RESET_ORDER_SUMMARY':
      return {
        ...state,
        orderSummary: [],
      };
    case 'UPDATE_SALES_COUNT':
      return {
        ...state,
        salesCount: action.payload,
      };
    case 'INIT_SHORT_SUMMARY':
      return {
        ...state,
        snackableSummary: action.payload,
      };
    case 'INIT_AUDIT_BILLS':
      return {
        ...state,
        auditBills: action.payload,
      };
    case 'INIT_CATEGORY_WISE_SALES':
      return {
        ...state,
        catgeoryWiseSales: action.payload,
      };
    case 'LOGOUT':
      return {
        list: [],
        total_Count: 0,
        total_Amount: 0,
        salesCount: {},
        orderSummary: [],
        snackableSummary: [],
        auditBills: [],
        catgeoryWiseSales: [],
      };
    default:
      return state;
  }
}
