export const INIT_EMPLOYEES = 'INIT_EMPLOYEES';
export const ADD_NEW_EMPLOYEE = 'ADD_NEW_EMPLOYEE';
export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE';
export const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE';
export const INIT_EMPLOYEE_ATTENDANCE = 'INIT_EMPLOYEE_ATTENDANCE';
export const UPDATE_EMPLOYEE_ATTENDANCE = 'UPDATE_EMPLOYEE_ATTENDANCE';
export const INIT_ALL_EMPLOYEE_ATTENDANCE = 'INIT_ALL_EMPLOYEE_ATTENDANCE';
export const UPDATE_STAFF_ATTENDANCE = 'UPDATE_STAFF_ATTENDANCE';
export const INIT_EMPLOYEE_SALARY = 'INIT_EMPLOYEE_SALARY';
export const UPDATE_EMPLOYEE_SALARY = 'UPDATE_EMPLOYEE_SALARY';
