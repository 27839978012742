import React, {lazy, useEffect} from 'react';
import {ThemeProvider} from '@material-ui/core/styles';
import {darkTheme, lightTheme} from './utils/themes/theme';
import './App.scss';
import {Switch} from 'react-router';
import suspenseHoc from './hoc/suspenseHoc';
import PrivateAuthHoc from './hoc/PrivateAuthHoc';
import LandingAuthHoc from './hoc/LandingAuthHoc';
import headerHoc from './hoc/headerHoc';
import LoadingModal from './modules/LoadingModal';
import {toast} from 'react-toastify';

const LandingPage = lazy(() => import('./pages/LandingPage'));
const DashboardPage = lazy(() => import('./pages/DashboardPage'));
const ManageItemsPage = lazy(() => import('./pages/ManageItemsPage'));
const PreferencesPage = lazy(() => import('./pages/PreferencesPage'));
const ReportsPage = lazy(() => import('./pages/ReportsPage'));
// const PayoutPage = lazy(() => import('./pages/PayoutPage'));
const ProfilePage = lazy(() => import('./pages/ProfilePage'));
const EmployeePage = lazy(() => import('./pages/EmployeePage'));
const CrmPage = lazy(() => import('./pages/CrmPage'));

function App() {
  useEffect(() => {
    window.onload = function () {
      const appVersion = window.localStorage.getItem('appVersion');
      if (!appVersion) {
        window.localStorage.setItem('appVersion', '0.03.03m');
      } else if (appVersion !== '0.03.03m') {
        toast.info('Please wait, updating new version...');
        setTimeout(() => {
          window.localStorage.clear();
          window.localStorage.setItem('appVersion', '0.03.03m');
          window.location.reload();
        }, 3000);
      }
    };
  }, []);
  const theme = window.localStorage.theme ? window.localStorage.theme : 'light';
  return (
    <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
      <LoadingModal />
      <Switch>
        <PrivateAuthHoc
          exact
          path="/dashboard"
          component={suspenseHoc(headerHoc(DashboardPage))}
        />
        <PrivateAuthHoc
          exact
          path="/manage-items"
          component={suspenseHoc(headerHoc(ManageItemsPage))}
        />
        <PrivateAuthHoc
          exact
          path="/preferences"
          component={suspenseHoc(headerHoc(PreferencesPage))}
        />
        <PrivateAuthHoc
          path="/reports"
          component={suspenseHoc(headerHoc(ReportsPage))}
        />
        <PrivateAuthHoc
          exact
          path="/crm"
          component={suspenseHoc(headerHoc(CrmPage))}
        />
        <PrivateAuthHoc
          exact
          path="/profile"
          component={suspenseHoc(headerHoc(ProfilePage))}
        />
        <PrivateAuthHoc
          exact
          path="/employees"
          component={suspenseHoc(headerHoc(EmployeePage))}
        />
        <LandingAuthHoc exact path="/" component={suspenseHoc(LandingPage)} />
      </Switch>
    </ThemeProvider>
  );
}

export default App;
