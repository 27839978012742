const initialState = {
  categories: [],
  subCategories: [],
  icons: [],
  selectedCateg: {},
  selectedSubCateg: {},
  items: [],
  sortedItems: [],
  homeCurrentCategory: {},
  homeSelectedItems: [],
  globalAddon: [],
};

export default function itemReducer(state = initialState, action) {
  switch (action.type) {
    case 'INIT_CATEGORY':
      const value = [...action.payload];
      const allSubCategory = [];
      value.length &&
        value.forEach((obj) => {
          if (obj.sub_category && obj.sub_category.length > 0) {
            obj.sub_category.forEach((sub) => {
              allSubCategory.push(sub);
            });
          }
        });
      return {
        ...state,
        categories: action.payload,
        subCategories: allSubCategory,
        selectedCateg: {},
        selectedSubCateg: {},
        homeCurrentCategory: {},
      };
    case 'ADD_CATEGORY':
      const addCategory = [...state.categories];
      addCategory.push(action.payload);
      return {
        ...state,
        categories: addCategory,
      };
    case 'SET_ICONS':
      return {
        ...state,
        icons: action.payload,
      };
    case 'UPDATE_SELECTED_CATEGORY':
      const updateTemp = [...state.categories];
      const updateSubCategory = [];
      if (action.payload._id) {
        if (action.payload.sub_category) {
          action.payload.sub_category.forEach((sub) => {
            updateSubCategory.push(sub);
          });
        }
      } else {
        updateTemp.length &&
          updateTemp.forEach((obj) => {
            if (obj.sub_category && obj.sub_category.length > 0) {
              obj.sub_category.forEach((sub) => {
                updateSubCategory.push(sub);
              });
            }
          });
      }
      let tempItems = [];
      if (action.payload._id) {
        state.items.forEach((obj) => {
          if (obj.category === action.payload._id) {
            tempItems.push(obj);
          }
        });
      } else {
        tempItems = [...state.items];
      }
      return {
        ...state,
        subCategories: updateSubCategory,
        selectedCateg: action.payload,
        selectedSubCateg: {},
        sortedItems: tempItems,
      };
    case 'UPDATE_SELECTED_SUB_CATEGORY':
      let temp_items = [];
      if (action.payload._id) {
        state.items.forEach((obj) => {
          if (obj.sub_category === action.payload._id) {
            temp_items.push(obj);
          }
        });
      } else {
        if (state.selectedCateg._id) {
          state.items.forEach((obj) => {
            if (obj.category === state.selectedCateg._id) {
              temp_items.push(obj);
            }
          });
        } else {
          temp_items = [...state.items];
        }
      }
      return {
        ...state,
        selectedSubCateg: action.payload,
        sortedItems: temp_items,
      };
    case 'INIT_ITEM':
      return {
        ...state,
        selectedCateg: {},
        selectedSubCateg: {},
        items: action.payload,
        homeSelectedItems: action.payload,
        sortedItems: action.payload,
      };
    case 'ADD_ITEMS':
      const add_item = [...state.items];
      const add_sorted_items = [...state.sortedItems];
      const add_home_selected_items = [...state.homeSelectedItems];
      add_item.push(action.payload);
      if (action.payload.category === state.selectedCateg._id) {
        add_sorted_items.push(action.payload);
      }
      if (action.payload.category === state.homeCurrentCategory._id) {
        add_home_selected_items.push(action.payload);
      }
      return {
        ...state,
        items: add_item,
        sortedItems: add_sorted_items,
        homeSelectedItems: add_home_selected_items,
      };
    case 'UPDATE_ITEMS':
      const update_item = [...state.items];
      const update_sorted_item = [...state.sortedItems];
      const update_item_index = update_item.findIndex(
        (a) => a._id === action.payload._id
      );
      const update_sorted_item_index = update_sorted_item.findIndex(
        (a) => a._id === action.payload._id
      );
      const update_home_items = [...state.homeSelectedItems];
      const index3 = update_home_items.findIndex(
        (a) => action.payload._id === a._id
      );
      if (update_item_index > -1) {
        update_item[update_item_index] = action.payload;
      }
      if (update_sorted_item_index > -1) {
        update_sorted_item[update_sorted_item_index] = action.payload;
      }
      if (index3 > -1) {
        update_home_items[index3] = action.payload;
      }
      return {
        ...state,
        items: update_item,
        sortedItems: update_sorted_item,
        homeSelectedItems: update_home_items,
      };
    case 'HOME_CURRENT_CATEGORY':
      let current_items = [];
      if (action.payload._id) {
        state.items.forEach((obj) => {
          if (obj.category === action.payload._id) {
            current_items.push(obj);
          }
        });
      } else {
        current_items = [...state.items];
      }
      return {
        ...state,
        homeCurrentCategory: action.payload,
        homeSelectedItems: current_items,
      };
    case 'INIT_GLOBAL_ADDON':
      return {
        ...state,
        globalAddon: action.payload,
      };
    case 'ADD_GLOBAL_ADDON':
      const add_global_addon = [...state.globalAddon];
      add_global_addon.push(action.payload);
      return {
        ...state,
        globalAddon: add_global_addon,
      };
    case 'LOGOUT':
      return {
        categories: [],
        subCategories: [],
        icons: [],
        selectedCateg: {},
        selectedSubCateg: {},
        items: [],
        sortedItems: [],
        homeCurrentCategory: {},
        homeSelectedItems: [],
        globalAddon: [],
      };
    default:
      return state;
  }
}
